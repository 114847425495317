import _ from "lodash"
import React from "react"
import ReactDOMServer from "react-dom/server"
import marked from "marked"
import { parseRawHtml as parse } from "../utils"

import {
  Accordion,
  Card,
  Container,
  Header,
  Icon,
  Image,
  Segment,
  Modal,
  Button,
} from "semantic-ui-react"
import ResponsiveLayout from "../components/responsive-layout"
import SocialShare from "../components/social-share";
import "../components/styles.css"

import PageContent from "frontmatter-markdown-loader?modules!../../content/making-and-changing-constitutions.md"
import { attributes as globalTrendsFiguresAndTablesByTopicData } from "frontmatter-markdown-loader?modules!../../content/data/global-trends-figures-and-tables-by-topic.md"

const globalTrendsFiguresAndTablesByTopic = globalTrendsFiguresAndTablesByTopicData.topics


export default ({ data = PageContent.attributes }) => (
  <ResponsiveLayout title={data.title}>
    <Page content={data} />
  </ResponsiveLayout>
)


const Page = ({ content, isMobile }) => (

  <Container>

    <Segment basic style={{ marginTop: "4em", padding: "0em" }}>
      <Header as="h2" dividing>
        {content.introductionSection.title}
        <SocialShare config={content.introductionSection.social} float="right" />
      </Header>

      <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "3em" }}>
        {parse(marked(content.introductionSection.introduction))}
      </Segment>
    </Segment>

    <Segment basic id="global-trends-over-time" style={{ marginTop: "4em", padding: "0em" }}>

      <Header as="h2" dividing>
        {content.globalTrendsOverTimeSection.title}
        <SocialShare config={content.globalTrendsOverTimeSection.social} float="right" />
      </Header>

      <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em" }}>
        {parse(marked(content.globalTrendsOverTimeSection.introduction))}
      </Segment>

      <Header as="h3" style={{ marginTop: "3em" }}>
        {content.globalTrendsOverTimeSection.figuresAndTablesSubsection.title}
      </Header>

      <Card.Group stackable itemsPerRow={4}
        style={{
          marginTop: "2em",
        }}
        children={
          globalTrendsFiguresAndTablesByTopic.map((item) => (
            <Card centered style={{ marginTop: "2em" }}>
              <Modal closeIcon={true}
                trigger={
                  <a>
                    <Image bordered centered rounded src={item.topic.image} />
                  </a>
                }
                children={
                  <>
                    <Modal.Header>{item.topic.title}</Modal.Header>
                    <Modal.Content>
                      <Card.Group stackable centered itemsPerRow={3}
                        children={
                          item.topic.figuresAndTables.map((fig) => (
                            <Card centered style={{ marginTop: "2em" }}>
                              <Modal closeIcon={true}
                                trigger={
                                  <a>
                                    <Image bordered centered rounded src={fig.image} />
                                  </a>
                                }
                                children={
                                  <>
                                    <Modal.Header>
                                      {fig.title}
                                      {
                                        fig.referenceLink &&
                                        <a href={fig.referenceLink} target="_blank" style={{ marginLeft: "1em" }}>
                                          <Icon size="small" name="external" />
                                        </a>
                                      }
                                    </Modal.Header>
                                    <Modal.Content>
                                      <Image bordered centered rounded src={fig.image} size="huge" style={{ marginTop: "1em" }} />
                                      <Modal.Description>
                                        <Segment basic className="MkdContent" style={{ marginTop: "2em", paddingLeft: "0em" }}>
                                          {fig.briefDescription && parse(marked(fig.briefDescription))}
                                        </Segment>
                                      </Modal.Description>
                                    </Modal.Content>
                                  </>
                                }
                              />
                              <Card.Content textAlign="center"
                                style={{
                                  borderTop: "0em",
                                }}
                                children={fig.title}
                              />
                            </Card>
                          ))
                        }
                      />
                    </Modal.Content>
                  </>
                }
              />
              <Card.Content textAlign="center"
                style={{
                  marginTop: "1em",
                  borderTop: "0em",
                }}
                children={
                  <Header as={isMobile ? "h3" : "h4"}>{item.topic.title}</Header>
                }
              />
            </Card>
          ))
        }
      />

      {
        content.globalTrendsOverTimeSection.figuresAndTablesSubsection.credits &&
        <Segment basic className="MkdContent"
          style={{
            padding: "0em",
            marginTop: isMobile ? "4em" : "3em"
          }}
          content={parse(marked(content.globalTrendsOverTimeSection.figuresAndTablesSubsection.credits))}
        />
      }

    </Segment>

    <Segment basic id="guides-and-resources" style={{ marginTop: "4em", padding: "0em" }}>

      <Header as="h2" dividing>
        {content.guidesAndResourcesSection.title}
        <SocialShare config={content.guidesAndResourcesSection.social} float="right" />
      </Header>

      {
        content.guidesAndResourcesSection.introduction &&
        <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "3em" }}>
          {parse(marked(content.guidesAndResourcesSection.introduction))}
        </Segment>
      }

      <GuidesAndResources orgs={content.guidesAndResourcesSection.guidesAndResourcesByOrganization} />

    </Segment>

    {
      content.credits &&
      <Segment basic className="MkdContent"
        style={{
          padding: "0em",
          marginTop: "4em",
        }}
        content={parse(marked(content.credits))}
      />
    }

  </Container >

)


class GuidesAndResources extends React.Component {
  state = {
    activeIndices: {
      0: true
    }
  }
  toggleItemVisibility = (e, titleProps) => {
    if (this.state.activeIndices[titleProps.index]) {
      delete this.state.activeIndices[titleProps.index]
    }
    else {
      this.state.activeIndices[titleProps.index] = true
    }
    this.setState({
      activeIndices: this.state.activeIndices
    })
  }
  render() {
    return (
      <Accordion fluid styled style={{ marginTop: "3em" }}>
        {this.props.orgs.map((item, i) => (
          <>
            <Accordion.Title
              index={i}
              // active={!!this.state.activeIndices[i]}
              active={true}
              onClick={this.toggleItemVisibility}
              style={{ fontSize: "1em" }}
            >
              <Icon name="dropdown" />
              {item.organization.title}
            </Accordion.Title>
            <Accordion.Content active={!!this.state.activeIndices[i]}>
              <Segment basic style={{ marginTop: "2em", padding: "0em" }}>
                <Image rounded wapped size="small" floated="left"
                  src={item.organization.image}
                />
                {
                  item.organization.briefDescription &&
                  <Segment basic className="MkdContent" style={{ padding: "0em", margin: "0em" }}>
                    {parse(marked(item.organization.briefDescription))}
                  </Segment>
                }
              </Segment>
              <Card.Group stackable itemsPerRow={5}
                style={{
                  marginTop: "4em",
                  // maxHeight: "50em",
                  // overflow: "auto"
                }}
                children={item.organization.guidesAndResources && item.organization.guidesAndResources.map((resource, j) => (
                  <Card>
                    <Modal closeIcon={true} trigger={<Image as="a" src={resource.image} wrapped ui={false} />}>
                      <Modal.Header>
                        {resource.title}
                        {
                          resource.referenceLink &&
                          <a href={resource.referenceLink} target="_blank" style={{ marginLeft: "1em" }}>
                            <Icon size="small" name="external" />
                          </a>
                        }
                      </Modal.Header>
                      <Modal.Content>
                        <a target="_blank"
                          href={resource.linkedFileSelfHosted || resource.linkedFileOther || resource.referenceLink}
                          children={
                            <Image bordered centered rounded src={resource.image} size="medium" style={{ marginTop: "1em" }} />
                          }
                        />
                        <Modal.Description style={{ overflow: "auto" }}>
                          <Segment basic className="MkdContent" style={{ marginTop: "2em", paddingLeft: "0em" }}>
                            {parse(`
                              ${marked(resource.briefDescription)}
                              ${ReactDOMServer.renderToStaticMarkup(
                              <Button primary as="a" target="_blank" floated="right"
                                href={resource.linkedFileSelfHosted || resource.linkedFileOther || resource.referenceLink}
                                content="Download"
                              />
                            )}
                            `)}
                          </Segment>
                        </Modal.Description>
                      </Modal.Content>
                    </Modal>
                    <Card.Content textAlign="center" style={{
                      marginTop: "1em",
                      borderTop: "0em",
                      paddingBottom: "0.5em",
                    }}>
                      {resource.title}
                    </Card.Content>
                  </Card>
                ))}
              />
            </Accordion.Content>
          </>
        ))}
      </Accordion>
    )
  }
}
